<template>
    <div id="contact">
        <hr class="menu-separator">
        <div class="page-title">
            <h2>
                Contact
            </h2>
        </div>
        <div class="container">
            <div class="info">
                <a href="tel:+310.344.0898" class="phone">
                    310.344.0898
                </a>
                <a href="mailto:ryan@coastalluxeliving.com" class="email">
                    ryan@coastalluxeliving.com
                </a>
                <hr>
                <p>
                    Berkshire Hathaway HomeServices California Properties<br>
                    11677 San Vicente Blve #307<br>
                    Los Angeles CA 90049
                </p>
            </div>
            <div class="image-container">
                <img src="/images/contact.jpg" alt="" srcset="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Contact",
    data(){
        return{
            title: "Contact",
            phone: "310.492.0737",
            address: {
                street: "257 North Canon Drive",
                city: "Beverly Hills",
                zipcode: "CA 90210"
            },
            email: "info@paulsalazargroup.com",
        }
    },
    metaInfo: {
        title: 'Contact',
        titleTemplate: '%s | Coastal Luxe',
        meta: [{
            name: 'description', 
            content: "Coastal Luxe"
        }]
    },
}
</script>

<style lang="scss" scoped>
.container{
    display: flex;
    padding: 2rem 0;
    align-items: center;
    margin-bottom: 4rem;
    .image-container{
        width: 50%;
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    .info{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 4rem 0;
        a{
            font-size: 18px;
            letter-spacing: 2px;
            text-decoration: none;
            margin: 1rem 0;
            margin-left: 30%;
            color: #000;
        }
        .phone{
            font-size: 50px;
        }
        p{
            font-size: 18px;
            letter-spacing: 2px;
            margin: 0;
            margin-left: 30%;
            max-width: 350px;
            br {
                content: " ";
                display: block;
                margin: 8px 0;
            }
        }
        hr{
            border: none;
            height: 1px;
            /* Set the hr color */
            color: #000; /* old IE */
            background-color: rgba(0,0,0,0.25); /* Modern Browsers */
            width: 60%;
            margin-left: 30%;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .mail{
            padding-top: 2rem;
        }
    }
}
@media (max-width: $mobile) {
    .container{
        padding-top: 0;
        flex-wrap: wrap;
        .image-container{
            width: 100%;
        }
        .info{
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 1rem 0;
            a{
                margin: 0.5rem auto;
            }
            p{
                margin: 0;
                margin-left: 2rem;
            }
            .phone{
                margin-top: 0;
                font-size: 18px;
            }
            hr{
                width: 80%;
                margin: 1.75rem auto;
            }
        }
    }
}
</style>
